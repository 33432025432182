import config from './../config.json';
class AuthService {
    async login(sede, contraseña) {
        try {
            const response = await fetch(`${config.apiBaseUrl}/Usuario/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ sede, contraseña }),
            });

            const data = await response.json();

            if (response.ok) {
                return { success: true, message: data };
            } else {
                const errorMessage = data.message || 'Error desconocido';
                return { success: false, message: errorMessage };
            }
        } catch (error) {
            console.error('Error en la red:', error);
            return { success: false, message: 'Error en la red.' };
        }
    }
}

export default new AuthService();
