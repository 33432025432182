import React, { useState } from 'react';
import { InputText } from "primereact/inputtext";
import { Link } from "react-router-dom";
import { Button } from 'primereact/button';
import Header from "../Header/Header";
import './Albaran_sent.css';

export default function Albaran_sent () {

    const AlbaranData = {
        username: 'Marc Torres',
        numero: '001',
        dni: '12345678A',
        obra: 'obra test',
        partida: 'partida test'
    }

    const sendAlbaran = () => {
        alert("SEND photo");
    }

    return (
        <div className="Albaran_container">
            <Header username={AlbaranData.username} />
            <div className="Albaran_contents">
                <div className="Albaran_sent_dades">
                    <div className="Albaran_sent_pregunta flex justify-content-center">
                        <div className="flex flex-column gap-2">
                            <span className="Albaran_sent_alert_icon pi pi-check" style={{cursor:'pointer'}}></span>
                            <span className="Albaran_sent_infomacio">Documento enviado correctamente</span>
                        </div>
                    </div>
                    <div className="Albaran_sent_dades card flex justify-content-center">
                        <div className="Albaran_sent_dada flex flex-column gap-2">
                            <label>ALBARÁN</label>
                            <InputText id="obra" value={AlbaranData.numero} />
                        </div>
                    </div>
                    <div className="Albaran_sent_dades card flex justify-content-center">
                        <div className="Albaran_sent_dada flex flex-column gap-2">
                            <label>OBRA</label>
                            <InputText id="obra" value={AlbaranData.obra} />
                        </div>
                    </div>
                    <div className="Albaran_sent_dades card flex justify-content-center">
                        <div className="Albaran_sent_dada flex flex-column gap-2">
                            <label>Responsabe</label>
                            <InputText id="obra" value={AlbaranData.username} />
                        </div>
                    </div>
                    <div className="Albaran_sent_dades card flex justify-content-center">
                        <div className="Albaran_sent_buttons flex flex-column gap-2">
                            <Link to="/Albaran_scan">
                                <div class="Albaran_delete_photo_button">Escanear de nuevo</div>
                            </Link>
                            <Link to="/Albarans">
                                <Button className="Albaran_sent_button" label="Ver albaranes" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}