import React, { useState } from 'react';
import { InputText } from "primereact/inputtext";
import { Link } from "react-router-dom";
import { Button } from 'primereact/button';
import Header from "../Header/Header";
import './Albaran_send.css';

export default function Albaran_send () {

    const AlbaranData = {
        username: 'Marc Torres',
        numero: '001',
        dni: '12345678A',
        obra: 'obra test',
        partida: 'partida test'
    }

    const sendAlbaran = () => {
        alert("SEND photo");
    }

    return (
        <div className="Albaran_container">
            <Header username={AlbaranData.username} />
            <div className="Albaran_contents">
                <div className="Albaran_send_dades">
                    <div className="Albaran_send_pregunta flex justify-content-center">
                        <div className="flex flex-column gap-2">
                            <span className="Albaran_send_alert_icon pi pi-exclamation-triangle" style={{cursor:'pointer'}}></span>
                            <span className="Albaran_send_infomacio">Está seguro que quiere enviar el albarán?</span>
                        </div>
                    </div>
                    <div className="Albaran_send_dades card flex justify-content-center">
                        <div className="Albaran_send_dada flex flex-column gap-2">
                            <label>ALBARÁN</label>
                            <InputText id="obra" value={AlbaranData.numero} />
                        </div>
                    </div>
                    <div className="Albaran_send_dades card flex justify-content-center">
                        <div className="Albaran_send_dada flex flex-column gap-2">
                            <label>OBRA</label>
                            <InputText id="obra" value={AlbaranData.obra} />
                        </div>
                    </div>
                    <div className="Albaran_send_dades card flex justify-content-center">
                        <div className="Albaran_send_dada flex flex-column gap-2">
                            <label>Responsabe</label>
                            <InputText id="obra" value={AlbaranData.username} />
                        </div>
                    </div>
                    <div className="Albaran_send_dades card flex justify-content-center">
                        <div className="Albaran_send_buttons flex flex-column gap-2">
                            <Link to="/Albaran_scan">
                                <div class="Albaran_delete_photo_button">Cancelar</div>
                            </Link>
                            <Link to="/Albaran_sent" onClick={sendAlbaran}>
                                <Button className="Albaran_send_button" label="Enviar" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}