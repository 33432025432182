import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from './components/Login/Login';
import Albarans from './components/Albarans/Albarans';
import Albarans_Purchase from './components/Albaran_Purchase/Albaran_Purchase';
import Albaran_scan from './components/Albaran_scan/Albaran_scan';
import Albaran_detail from './components/Albaran_detail/Albaran_detail';
import Albaran_send from './components/Albaran_send/Albaran_send';
import Albaran_sent from './components/Albaran_sent/Albaran_sent';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import './App.css';
import { SedeProvider } from './Services/SedeContext';

export default function App() {
  return (
    <div className="App">
  <SedeProvider> 
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/Albarans_Purchase' element={<Albarans_Purchase />} />
        <Route path='/Albaran_scan' element={<Albaran_scan />} />
        <Route path='/Albaran_detail/:albaranId' element={<Albaran_detail />} />
        <Route path='/Albaran_send' element={<Albaran_send />} />
        <Route path='/Albaran_sent' element={<Albaran_sent />} />
        <Route path='/Albarans' element={<Albarans />} />
      </Routes>
  </SedeProvider>
    </div>
  );
}