import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../Header/Header";
import './Albaran_detail.css';
import SedeContext from "../../Services/SedeContext";
import AlbaranService from '../../Services/AlbaranService';

export default function Albaran_scan() {
    const { sede } = useContext(SedeContext);
    const navigate = useNavigate();
    const { albaranId } = useParams();
    const [albara, setAlbaran] = useState(null); 

    useEffect(() => {
        if (!sede) {
            navigate('/');
            return; 
        }

        const fetchAlbaran = async () => {
            try {
                const data = await AlbaranService.getAllAlbaransByID(albaranId);
                
                setAlbaran(data); 
            } catch (error) {
                console.error('Error fetching albarans:', error);
             
            }
        };

        fetchAlbaran();
    }, [sede, navigate, albaranId]); 
    console.log('Albaran recibido:', albara); 
    
    if (albara === null) {
        return <div>Cargando albarán...</div>;
    }

    if (!albara || !albara.id) {
        return <div>No se encontraron datos de albará.</div>;
    }

    // Nueva lógica para la navegación basada en el valor de "sede"
    const handleNavigation = () => {
        if (sede === 'Obra') {
            navigate('/Albarans');
        } else {
            navigate('/Albarans_Purchase');
        }
    };

    return (
        <div className="Albaran_container">
            <Header username={sede} />
            <div className="Albaran_contents">
                <div className="Albaran_detail_title" onClick={handleNavigation} style={{ cursor: 'pointer' }}>
                    <span className="pi pi-delete-left"></span>
                    <span className="Albaran_detail_title_title">ALBARAN:</span>
                    <span className="Albaran_detail_title_text">{albara.albaran}</span>
                </div>
                <div className="card">
                    {albara.conceptes.map((concepte) => (
                        <div key={concepte.id} className="Albaran_detail_concepte">
                            <div className="Albaran_detail_concepte_albara">{albara.id}</div>
                            <div className="Albaran_detail_concepte_concepte">{concepte.concepte}</div>
                            <div className="Albaran_detail_concepte_dades">
                                <div className="Albaran_detail_concepte_dada">
                                    <span className="Albaran_detail_concepte_data_titol">CANTIDAD</span>
                                    <span className="Albaran_detail_concepte_data_text">{concepte.quantitat}</span>
                                </div>
                                <div className="Albaran_detail_concepte_dada">
                                    <span className="Albaran_detail_concepte_data_titol">PRECIO</span>
                                    <span className="Albaran_detail_concepte_data_text">{concepte.preu}</span>
                                </div>
                            </div>
                            <div className="Albaran_detail_concepte_dades">
                                <div className="Albaran_detail_concepte_dada">
                                    <span className="Albaran_detail_concepte_data_titol">DTO</span>
                                    <span className="Albaran_detail_concepte_data_text">{concepte.descompte} %</span>
                                </div>
                                <div className="Albaran_detail_concepte_dada">
                                    <span className="Albaran_detail_concepte_data_titol">IMPORTE</span>
                                    <span className="Albaran_detail_concepte_data_text">{concepte.import}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
