import React, { useState, useRef ,useContext } from "react";
import { useNavigate } from "react-router-dom"; 
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import AuthService from '../../Services/AuthService';
import mainLogo from '../../assets/logo.png';
import './Login.css';
import 'primeicons/primeicons.css';
import SedeContext from "../../Services/SedeContext";


export default function Login() {
    const { sede, setSede } = useContext(SedeContext);
    const [password, setPassword] = useState('');
    const toast = useRef(null);
    const navigate = useNavigate();

    const handleLogin = async () => {
        const { success, message } = await AuthService.login(sede, password);
    
        if (success) {
            setSede(message.usuario.perfil); 
           localStorage.setItem('IdSede', message.usuario.id);
           localStorage.setItem('LocalSede', message.usuario.sede);
          toast.current.show({ severity: 'success', summary: 'Éxito', detail: message.message, life: 3000 });
            if (message.usuario.perfil === 'Obra') { 
                navigate('/Albaran_scan');
            } else { 
                navigate('/Albarans_Purchase');
            }
        } else {
            console.error('Error:', message);  // Verifica el mensaje en la consola
            toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
        }
    };
    

    return (
        <>
            <header className="Login_header">
                    <img src={mainLogo} className='Login_logo' alt='Elèctrica Güell' />
            </header>

            <div className="Login_container">               
                <div className="Login_form">
                    <IconField iconPosition="left" className="Login_field" style={{marginBottom: '10px', marginTop: '10vh'}}>
                        <InputIcon className="pi pi-user"> </InputIcon>
                        <InputText 
                            value={sede} 
                            onChange={(e) => setSede(e.target.value)} 
                            placeholder="Sede" 
                            className="Login_input" 
                        />
                    </IconField>
                    <IconField iconPosition="left" className="Login_field">
                        <InputIcon className="pi pi-lock"> </InputIcon>
                        <InputText 
                            value={password} 
                            onChange={(e) => setPassword(e.target.value)} 
                            placeholder="Contraseña" 
                            type="password" 
                            className="Login_input" 
                            onKeyDown={(e) => { if (e.key === 'Enter') handleLogin(); }}
                        />
                    </IconField>
                    
                    <Button className="Login_button" label="Entrar" onClick={handleLogin} />
                </div>
            </div>
            <Toast ref={toast} />
        </>
    );
}
