
import config from './../config.json';
const API_URL = `${config.apiBaseUrl}/Albaran`;
const API_OBRA_URL = `${config.apiBaseUrl}/Obra`;
class AlbaranService {
    async Validar( dni) {
        try {
            const formData = new FormData();
            
            formData.append('CodigoTrabajador', dni);

            const response = await fetch(`${config.apiBaseUrl}/Empleados/Validar`, {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                const errorText = await response.text();
                return { success: false, message: errorText }; 
            }else {
                return { success: true, message: ''};
            }
        } catch (error) {
            console.error('Error en la red:', error);
            return { success: false, message: 'Error en la red.' };
        }
    }

    async getAllAlbarans(estados = []) {
        try {
            // Asegúrate de que 'estados' sea siempre un array
            const estadoArray = Array.isArray(estados) ? estados : [estados];
            
            // Obtener el 'idusuario' y 'dniTrabajador' desde localStorage
            const idusuario = localStorage.getItem('IdSede')|| 0;
            const dniTrabajador = localStorage.getItem('dni') || '';
    
            // Construir los parámetros de consulta para los estados
            const estadoParams = estadoArray.length > 0
                ? estadoArray.map(id => `estado=${id}`).join('&')
                : '';
    
            // Añadir los parámetros de idusuario y dniTrabajador
            const queryParams = [
                estadoParams,                          // Parámetros de estados
                `idusuario=${idusuario}`,              // Agregar idusuario
                dniTrabajador ? `dniUsuario=${dniTrabajador}` : ''  // Agregar dniTrabajador si existe
            ].filter(param => param).join('&');       // Filtrar para evitar parámetros vacíos
    
            // Construir la URL completa con los parámetros
            const url = queryParams 
                ? `${config.apiBaseUrl}/Albaran?${queryParams}` 
                : `${config.apiBaseUrl}/Albaran`;
    
            const response = await fetch(url);
            
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Failed to fetch albarans:', error);
            throw error;
        }
    }
    
    
    

    async getAllObras() {
        try {
            const response = await fetch(API_OBRA_URL);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Failed to fetch albarans:', error);
            throw error;
        }
    }
    async getAllAlbaransByID(AlbaranId) {
        try {
            const apiUrl = `${API_URL}/${AlbaranId}`;
            const response = await fetch(apiUrl);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Failed to fetch albarans:', error);
            throw error;
        }
    }

    async getAlbaranPdf(id) {
        try {
            const response = await fetch(`${config.apiBaseUrl}/Albaran/GetAlbaranPdf?idAlbaran=${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/pdf'
                }
            });
    
            if (!response.ok) {
                throw new Error('Error al obtener el PDF');
            }
    
            const pdfBlob = await response.blob(); // Obtener como Blob
            return pdfBlob;
        } catch (error) {
            console.error('Error fetching the albaran PDF:', error);
            return null;
        }
    }
    

    async crearPedido(id) {
        try {
            const response = await fetch(`${config.apiBaseUrl}/Albaran/CrearPedido/${id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (!response.ok) {
                const errorText = await response.text();
                console.error('Error al crear el pedido:', errorText);
                return false; // Retorna false en caso de error
            }

            return true; // Retorna true si la solicitud fue exitosa
        } catch (error) {
            console.error('Error en la red:', error);
            return false; // Retorna false en caso de error de red
        }
    }

    async eliminar(id) {
        try {
            const response = await fetch(`${config.apiBaseUrl}/Albaran/eliminar/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (!response.ok) {
                const errorText = await response.text();
                console.error('Error al eliminar el albarán:', errorText);
                throw new Error(`Error al eliminar el albarán: ${errorText}`);
            }

            return true; // Retorna true si la solicitud fue exitosa
        } catch (error) {
            console.error('Error en la red o en el servidor:', error);
            throw error; // Lanza el error para que sea manejado por el frontend
        }
    }

    async reprocesar(id) {
        try {
            const response = await fetch(`${config.apiBaseUrl}/Albaran/reprocesar/${id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (!response.ok) {
                const errorText = await response.text();
                console.error('Error al reprocesar el albarán:', errorText);
                throw new Error(`Error al reprocesar el albarán: ${errorText}`);
            }

            return true; // Retorna true si la solicitud fue exitosa
        } catch (error) {
            console.error('Error en la red o en el servidor:', error);
            throw error; // Lanza el error para que sea manejado por el frontend
        }
    }
     async getEstados() {
        
        const response = await fetch(`${config.apiBaseUrl}/Albaran/GetEstados`);
        if (!response.ok) {
            throw new Error('Error fetching estados');
        }
        return await response.json();
    }
    
}

export default new AlbaranService();
