import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import Header from "../Header/Header";
import takePhotoButtonImg from '../../assets/take_photo.png';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import './Albaran_scan.css';
import { Message } from 'primereact/message';
import AlbaranService from "../../Services/AlbaranService";
import SedeContext from "../../Services/SedeContext";
import { AutoComplete } from 'primereact/autocomplete';
import config from './../../config.json';

const validateTrabajador = async (dni) => {
    const { success, message } = await AlbaranService.Validar(dni);
    return { success, message };
};

export default function Albaran_scan() {
    const { sede } = useContext(SedeContext);
    const [formData, setFormData] = useState({
        numero: localStorage.getItem('numero') || '',
        dni: localStorage.getItem('dni') || '',
        selectedObra: null,
        nombreObra: '',
        fotografias: [],
        partida: null,
        errors: { numero: '', dni: '', fotografia: '', obra: '' }
    });
    const navigate = useNavigate();
    const [obras, setObras] = useState([]);
    const [filteredObras, setFilteredObras] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (!sede) {
            navigate('/');
        }
        loadObras();
    }, [sede, navigate]);

    const redirectUrl = sede === 'Obra' ? '/Albarans' : '/Albarans_Purchase';
    useEffect(() => {
        localStorage.setItem('numero', formData.numero);
        localStorage.setItem('dni', formData.dni);
    }, [formData.numero, formData.dni]);

    const handleBlur = async () => {
        const validationMessage = await validateTrabajador(formData.dni);
        setFormData((prevData) => ({
            ...prevData,
            errors: {
                ...prevData.errors,
                dni: validationMessage.success ? '' : validationMessage.message
            }
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let hasError = false;
        let newErrors = { ...formData.errors };

        if (formData.fotografias.length === 0) {
            newErrors.fotografia = 'Debe seleccionar al menos una imagen.';
            hasError = true;
        } else {
            newErrors.fotografia = '';
        }

        if (!formData.selectedObra) {
            newErrors.obra = 'Debe seleccionar una obra.';
            hasError = true;
        } else {
            newErrors.obra = '';
        }

        setFormData((prevData) => ({
            ...prevData,
            errors: newErrors
        }));

        if (hasError) {
            return;
        }

        setIsSubmitting(true);

        const formPayload = new FormData();
        formPayload.append('IDOBRA', formData.selectedObra.id);
        formPayload.append('NumeroTrabajador', formData.numero);
        formPayload.append('CodigoTrabajador', formData.dni);
        formPayload.append('Partida', formData.partida);
        formPayload.append('NombreObra', formData.nombreObra);
        formPayload.append('Sede', localStorage.getItem('IdSede') || 0);

        formData.fotografias.forEach((foto) => {
            formPayload.append('Fotografias', foto.file);
        });
    
        try {
            const response = await fetch(`${config.apiBaseUrl}/Albaran/CrearAlbaran`, {
                method: 'POST',
                body: formPayload,
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(errorText);
            }

            navigate(redirectUrl);
        } catch (error) {
            alert(`Error: ${error.message}`);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleFileUpload = (e) => {
        const files = Array.from(e.target.files);
        const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
        const newPhotographs = files.map(file => {
            if (validImageTypes.includes(file.type)) {
                return {
                    file,
                    date: new Date().toLocaleString()
                };
            }
            return null;
        }).filter(file => file !== null);

        setFormData((prevData) => ({
            ...prevData,
            fotografias: [...prevData.fotografias, ...newPhotographs],
            errors: {
                ...prevData.errors,
                fotografia: ''
            }
        }));
    };

    const removePhoto = (index) => {
        setFormData((prevData) => {
            const newFotografias = prevData.fotografias.filter((_, i) => i !== index);
            return {
                ...prevData,
                fotografias: newFotografias,
                errors: {
                    ...prevData.errors,
                    fotografia: ''
                }
            };
        });
    };

    const loadObras = async () => {
        try {
            const data = await AlbaranService.getAllObras();
            setObras(data);
        } catch (error) {
            console.error('Error fetching obras:', error);
        }
    };

    const filterObras = (event) => {
        const filtered = obras.filter(obra => {
            return obra.descripcion.toLowerCase().includes(event.query.toLowerCase());
        });

        setFilteredObras(filtered);
    };

    const obraTemplate = (option) => {
        return (
            <div>
                {option.descripcion}
            </div>
        );
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
            errors: {
                ...prevData.errors,
                [name]: ''
            }
        }));
    };

    const handleObraSelect = (e) => {
        const selectedObra = e.value;

        if (selectedObra && selectedObra.referencia) {
            let nombreObraModificado = selectedObra.referencia;
            const primerosDosDigitos = nombreObraModificado.substring(0, 2);
            const terceraLetra = nombreObraModificado.charAt(2).toUpperCase();

            if (primerosDosDigitos >= 40 && primerosDosDigitos <= 44) {
                const letrasEspeciales = ['A', 'P', 'T', 'M', 'Q', 'X', 'G', 'Z', 'S', 'I', 'D', 'L'];
                if (letrasEspeciales.includes(terceraLetra)) {
                    nombreObraModificado = nombreObraModificado.substring(0, 2) + '000' + nombreObraModificado.substring(3);
                }
            }

            setFormData((prevData) => ({
                ...prevData,
                selectedObra: e.value,
                nombreObra: nombreObraModificado,
                errors: {
                    ...prevData.errors,
                    obra: ''
                }
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                selectedObra: e.value,
                errors: {
                    ...prevData.errors,
                    obra: ''
                }
            }));
        }
    };

    const viewPhoto = (file) => {
        const url = URL.createObjectURL(file);
        window.open(url, '_blank');
    };

    return (
        <div className="Albaran_container">
            <Header username={sede} />

            <div className="Albaran_contents">
                <div className="Albaran_albarans">
                    <Link to={redirectUrl}>
                        Ver albaranes <span className="pi pi-arrow-right"></span>
                    </Link>
                </div>
                <div className="Albaran_dades">
                    <div className="Albaran_dada flex justify-content-center">
                        <div className="flex flex-column gap-2">
                            <label htmlFor="n_trabajador">Nº trabajador</label>
                            <InputText
                                id="n_trabajador"
                                name="numero"
                                value={formData.numero}
                                onChange={handleChange}
                                className={formData.errors.numero ? 'p-invalid' : ''}
                            />
                            {formData.errors.numero && <Message severity="error" text={formData.errors.numero} />}
                        </div>
                    </div>
                    <div className="Albaran_dada card flex justify-content-center">
                        <div className="flex flex-column gap-2">
                            <label htmlFor="dni">DNI o código interno</label>
                            <InputText
                                id="dni"
                                name="dni"
                                value={formData.dni}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={formData.errors.dni ? 'p-invalid' : ''}
                            />
                            {formData.errors.dni && <Message severity="error" text={formData.errors.dni} />}
                        </div>
                    </div>

                    <div className="Albaran_dada card flex justify-content-center">
                        <div className="flex flex-column gap-2">
                            <label htmlFor="obra">Obra</label>
                            <AutoComplete
                                value={formData.selectedObra}
                                suggestions={filteredObras}
                                completeMethod={filterObras}
                                field="descripcion"
                                onChange={handleObraSelect}
                                placeholder="Seleccione una obra"
                                itemTemplate={obraTemplate}
                                className={formData.errors.obra ? 'p-invalid' : ''}
                            />
                            {formData.errors.obra && <Message severity="error" text={formData.errors.obra} />}
                        </div>
                    </div>
                    <div className="Albaran_dada card flex justify-content-center">
                        <div className="flex flex-column gap-2">
                            <label htmlFor="nombre_obra">Nombre Obra</label>
                            <InputText
                                id="nombre_obra"
                                value={formData.nombreObra}
                                disabled
                            />
                        </div>
                    </div>
                    <div className="Albaran_dada card flex justify-content-center">
                        <div className="flex flex-column gap-2">
                            <label htmlFor="partida">Partida (opcional)</label>
                            <InputText
                                id="partida"
                                value={formData.partida}
                                onChange={handleChange}
                            />
                        </div>
                    </div>

                    <div className="Albaran_dada card flex justify-content-center">
                        <div className="flex flex-column gap-2">
                            {formData.fotografias.length > 0 && (
                                <div className="photo-list">
                                    {formData.fotografias.map((foto, index) => (
                                        <div key={index} className="photo-item">
                                            <div className="photo-info">
                                                <span>{foto.date}</span>
                                                <button onClick={() => viewPhoto(foto.file)}>
                                                    <i className="pi pi-eye"></i>
                                                </button>
                                                <button onClick={() => removePhoto(index)}>
                                                    <i className="pi pi-trash"></i>
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                            {formData.errors.fotografia && (
                                <Message severity="error" text={formData.errors.fotografia} />
                            )}
                            <div className="Albaran_camera flex-column gap-2">
                                <label htmlFor="fileUpload">Añadir imágenes</label>
                                <div className="Albaran_camera_round">
                                    <div
                                        onClick={() => document.getElementById('fileUpload').click()}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <img src={takePhotoButtonImg} alt='Tomar fotografía' />
                                        <input
                                            type="file"
                                            id="fileUpload"
                                            style={{ display: 'none' }}
                                            multiple
                                            onChange={handleFileUpload}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="Albaran_dada card flex justify-content-center">
                        <div className="flex flex-column gap-2">
                            <button className="custom-button" onClick={handleSubmit} disabled={isSubmitting}>
                                {isSubmitting ? (
                                    <i className="pi pi-spin pi-spinner" style={{ fontSize: '1.5rem' }}></i>
                                ) : (
                                    'Enviar'
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
