import React, { createContext, useState, useEffect } from 'react';

const SedeContext = createContext();

export const SedeProvider = ({ children }) => {
    const [sede, setSede] = useState(() => {
  
        return localStorage.getItem('sede') || '';
    });

    useEffect(() => {

        localStorage.setItem('sede', sede);
    }, [sede]);

    const logout = () => {
        setSede('');
        localStorage.removeItem('sede');
    };

    return (
        <SedeContext.Provider value={{ sede, setSede, logout }}>
            {children}
        </SedeContext.Provider>
    );
};

export default SedeContext;
