import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import mainLogo from '../../assets/logo.png';
import './Header.css';
import SedeContext from "../../Services/SedeContext";

export default function Header({ username }) {
    const { logout } = useContext(SedeContext);
    const navigate = useNavigate();

    const logOff = () => {
        logout();
        navigate('/'); 
    };
  

    return (
        <div className="Header">
            <img src={mainLogo} className='Header_logo' alt='Elèctrica Güell' />
            <div className="User_name">
                {username}
            </div>
            <div className="User_logoff" onClick={logOff}>
                <span className="pi pi-power-off"></span>
            </div>
        </div>
    );
}
